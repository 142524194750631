import React from 'react';
import '../assets/scss/pages/about.scss';

function About() {

    return (
      <section id="about" className="about">
          This about section. <br />
          Coming Soon.
      </section>
    );

}

export default About;